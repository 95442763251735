/* Apply to all elements */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Ensure no white space and optimize for mobile */
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  scroll-behavior: smooth;
  /* Optional: Ensures smooth scrolling on page transitions */
}

* {
  box-sizing: inherit;
}

body {
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* index.css */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&family=Roboto:wght@400;500&display=swap');

body {
    font-family: 'Roboto', sans-serif; /* Example default font */
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Poppins', sans-serif; /* Example heading font */
}


/* Ensure images and other media scale correctly */
img,
video {
  max-width: 100%;
  height: auto;
  display: block;
  /* Ensures there are no inline gaps */
}

/* Prevent oversized elements from overflowing */
.container,
.wrapper,
.content {
  max-width: 100%;
  overflow-x: hidden;
  padding: 0;
  /* Ensures there's no unnecessary padding */
}

/* Avoid bottom scrolling issues */
html,
body,
#root {
  height: 100%;
}

/* Mobile optimization */
@media (max-width: 600px) {
  body {
    font-size: 14px;
    /* Adjust font size for smaller screens */
  }

  .container,
  .wrapper {
    padding: 10px;
  }

  /* Ensure no extra space at bottom of page */
  #root,
  .content {
    min-height: 100vh;
    /* Ensure content takes up full height */
  }
}